import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { H1, H1Alt, H2, H2Alt, H3, H3Alt, H4, H5, H6, P, SM, XS } from '../js/components/Typography';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={0} __code={'<ThemeProvider theme={themeConWeb}>\n  <div style={{ minHeight: \'200px\' }}>\n    <H1 pb={4}>Heading 1 | Roboto Bold 50px</H1>\n    <H1Alt pb={4}>Alt Heading1 | Futura Bold 50px</H1Alt>\n    <H2 pb={4}>Heading 2 | Roboto Bold 40px</H2>\n    <H2Alt pb={4}>Alt Heading 2 | Futura Bold 40px</H2Alt>\n    <H3 pb={4}>Heading 3 | Roboto Bold 32px</H3>\n    <H3Alt pb={4}>Alt Heading 3 | Futura Bold 32px</H3Alt>\n    <H4 pb={4}>Heading 4 | Roboto Regular 21px</H4>\n    <H5 pb={4}>Heading 5 | Roboto Regular 24px</H5>\n    <H6 pb={4}>Heading 6 | Roboto Bold 20px</H6>\n    <P pb={4}>Body Copy | Roboto Regular 14px</P>\n    <SM pb={4}>Sub Copy | Roboto Regular 12px</SM>\n    <XS pb={4}>Sub (sub) Copy | Roboto Regular 10px</XS>\n  </div>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      H1,
      H1Alt,
      H2,
      H2Alt,
      H3,
      H3Alt,
      H4,
      H5,
      H6,
      P,
      SM,
      XS,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <div style={{
          minHeight: '200px'
        }}>
    <H1 pb={4} mdxType="H1">Heading 1 | Roboto Bold  50px</H1>
    <H1Alt pb={4} mdxType="H1Alt">Alt Heading1 | Futura Bold 50px</H1Alt>
    <H2 pb={4} mdxType="H2">Heading 2 | Roboto Bold 40px</H2>
    <H2Alt pb={4} mdxType="H2Alt">Alt Heading 2 | Futura Bold 40px</H2Alt>
    <H3 pb={4} mdxType="H3">Heading 3 | Roboto Bold 32px</H3>
    <H3Alt pb={4} mdxType="H3Alt">Alt Heading 3 | Futura Bold 32px</H3Alt>
    <H4 pb={4} mdxType="H4">Heading 4 | Roboto Regular 21px</H4>
    <H5 pb={4} mdxType="H5">Heading 5 | Roboto Regular 24px</H5>
    <H6 pb={4} mdxType="H6">Heading 6 | Roboto Bold 20px</H6>
    <P pb={4} mdxType="P">Body Copy | Roboto Regular 14px</P>
    <SM pb={4} mdxType="SM">Sub Copy | Roboto Regular 12px</SM>
    <XS pb={4} mdxType="XS">Sub (sub) Copy | Roboto Regular 10px</XS>
  </div>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      